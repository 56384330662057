import * as Yup from "yup"

export const fieldReducer = (acc, field) => {
  acc[field.name] = {
    name: field.name,
    label: field.label,
    type: field.type,
    required: field.required,
  }
  return acc
}

export const getYupType = (type, required) => {
  if (required) {
    return type == "checkbox"
      ? Yup.bool().required().oneOf([true], "")
      : Yup.string().required()
  } else {
    return type == "checkbox"
      ? Yup.bool().required().oneOf([true], "")
      : Yup.string()
  }
}
