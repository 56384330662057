import React, { useState } from "react"
import { Box, Checkbox, Flex, Label } from "theme-ui"
import { navigate } from "gatsby"
import { fieldReducer, getYupType } from "./form-helpers"
import * as Yup from "yup"
import { useFormik } from "formik"
import FormSubmit from "./form-submit"
import FormField from "./form-field"

const FormModule = (props) => {
  const { formData, color: colorProp } = props
  const { formId, terms, submitLabel } = formData
  const formFieldsData = formData.childrenContentfulFormContentfulfieldsJsonNode
  const color = colorProp != undefined || colorProp != "" ? colorProp : "#000"

  const [submissionSuccess, setSubmissionSuccess] = useState(false)

  if (!formFieldsData) return <></>

  const fields = formFieldsData.reduce(fieldReducer, {})

  const initialValues = Object.values(fields).reduce((acc, field) => {
    acc[field.name] = ""
    return acc
  }, {})

  const ValidationSchema = Yup.object().shape(
    Object.values(fields).reduce(
      (acc, field) => {
        acc[field.name] = getYupType(field.type, field.required)
        return acc
      },
      {
        accepts_terms: terms
          ? Yup.bool().required().oneOf([true], "")
          : Yup.bool(),
      }
    )
  )

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      const formData = new FormData()
      formData.append("form-name", formId)
      Object.entries(values).map(([key, val]) => formData.append(key, val))
      const queryString = new URLSearchParams(formData).toString()
      try {
        const response = await fetch("/", {
          method: "POST",
          body: formData,
        })
        if (response.status === 200) {
          setSubmissionSuccess(true)
          navigate(`?${queryString}`)
        } else {
          console.log("error")
          console.log(response)
        }
      } catch (error) {
        console.log(error)
      }
    },
  })

  return (
    <Flex
      {...props}
      sx={{
        alignItems: "flex-start",
        flexDirection: "column",
        ...props.sx,
      }}
      as="form"
      name={formId}
      data-netlify="true"
      onSubmit={formik.handleSubmit}
    >
      {Object.values(fields).map((field) => {
        return (
          <FormField
            type={field.type}
            formik={formik}
            field={field}
            color={color}
          />
        )
      })}
      {terms && terms.length > 0 && (
        <Flex mt={4} key={"accepts_terms"} sx={{ flexDirection: "column" }}>
          <Box sx={{ fontSize: "0.7rem", mb: "2" }}>{terms}</Box>
          <Label
            sx={{
              fontSize: "0.7rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              required={true}
              defaultChecked={false}
              name={"accepts_terms"}
              value={formik.values["accepts_terms"]}
              onChange={formik.handleChange}
              sx={{
                width: "18px",
                borderRadius: "0",
                border: "none",
              }}
            />
            I accept
          </Label>
        </Flex>
      )}
      {!submissionSuccess ? (
        <FormSubmit color={color} formik={formik} text={submitLabel} />
      ) : (
        <Box mt={3}>Form submitted. We'll get back to you shortly.</Box>
      )}
    </Flex>
  )
}

export default FormModule
