import React from "react"
import Caret from "../../assets/caret.png"
import { Button, Box, Flex } from "theme-ui"

const FormSubmit = (props) => {
  const { text, formik, color } = props

  return (
    <Button
      mt={3}
      px={3}
      py={2}
      sx={{
        opacity: formik.isValid ? "1" : "0.5",
        cursor: formik.isValid ? "pointer" : "not-allowed",
        borderRadius: "10px",
        border: "1px solid black",
        borderColor: color ? color : "black",
        background: "none",
        color: color ? color : "performance-dark",
        fontWeight: "bold",
        fontFamily: "pragmatica-extended",
        lineHeight: "1rem",
        textTransform: "uppercase",
      }}
      type="submit"
    >
      <Flex sx={{ alignItems: "center", columnGap: "2" }}>
        <Box>{text}</Box>
      </Flex>
    </Button>
  )
}

export default FormSubmit
