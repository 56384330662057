import React from "react"
import { Box } from "theme-ui"

const FormField = (props) => {
  const { field, formik, color, type = "text" } = props
  return (
    <>
      <Box sx={{ width: "100%" }} data-type={type}>
        <Box
          type={type}
          key={field.name}
          name={field.name}
          placeholder={field.label}
          value={formik.values[field.name]}
          onChange={formik.handleChange}
          px={2}
          py={1}
          mb={3}
          sx={{
            WebkitAppearance: "none",
            background: "none",
            border: "none",
            outline: "none",
            borderRadius: "0",
            outline: "none",
            borderBottom: "1px solid",
            borderColor: color ? color : "performance-dark",
            display: "block",
            width: "100%",
            fontSize: ["0.75rem", "0.75rem", "1rem"],
            fontFamily: "pragmatica-extended",
            fontWeight: "100",
            "::placeholder": {
              color: color ? color : "performance-darkgrey",
            },
          }}
          as="input"
        />
      </Box>
    </>
  )
}

export default FormField
